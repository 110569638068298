import { Link } from 'react-router-dom';
import logo from '~src/assets/img/logo.png';
import Navbar from './Navbar';
import User from './User';

const Header: React.FC = () => {
  return (
    <div className="flex items-center px-4 py-4 flex-nowrap bg-gray-900">
      <Link to="/" className="flex items-center">
        <img src={logo} width={50} height={50} alt="Soledium" />{' '}
        <span className="text-2xl font-sans">Soledium</span>
      </Link>

      <div className="ml-auto">
        <Navbar></Navbar>
      </div>
      <User></User>
    </div>
  );
};

export default Header;
