import { useCallback, useContext, useLayoutEffect, useRef } from 'react';

import { Context } from './ModalProvider';

export const useModal = (
  modal?: React.ReactNode,
  id?: string,
  backdropClick = true,
) => {
  const { onDismiss, onPresent } = useContext(Context);
  const ref = useRef<string>(id || '');

  const handlePresent = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    modal;
    ref.current = onPresent(modal, backdropClick);
  }, [backdropClick, modal, onPresent]);

  const handleDismiss = useCallback(() => {
    onDismiss(ref.current);
  }, [onDismiss]);

  return [handlePresent, handleDismiss];
};

export const useBodyClass = (toggle: boolean, className: string) => {
  useLayoutEffect(() => {
    if (toggle) {
      document.body.classList.add(className);
    } else {
      document.body.classList.remove(className);
    }
  }, [className, toggle]);
};
