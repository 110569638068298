import ExplorerLink from '../ExplorerLink';

export type NotificationContent = {
  id: string;
  type: 'wait' | 'error' | 'success';
  title: string;
  message: string;
  txid?: string;
};

export type NotificationType = NotificationContent['type'];

const NotificationPopup: React.FC<
  NotificationContent & {
    onDismiss: (id: string) => void;
  }
> = ({ id, type, title, message, txid, onDismiss }) => {
  const color = {
    wait: 'text-indigo-700',
    error: 'text-red-700',
    success: 'text-greeen-700',
  }[type];

  return (
    <div className="p-4 bg-black shadow-2xl relative mb-6 rounded-lg">
      <button
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-200"
        onClick={() => onDismiss(id)}
      >
        <i class="uil uil-times-circle"></i>
      </button>
      <div className={`text-lg ${color} mb-3`}>{title}</div>
      <div className="text-gray-400">{message}</div>
      {txid ? (
        <div className="text-sm mt-4 text-blue-600">
          <ExplorerLink address={txid} type="tx">
            View transaction
          </ExplorerLink>
        </div>
      ) : null}
    </div>
  );
};

export default NotificationPopup;
