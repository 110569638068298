import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
  LedgerWalletAdapter,
  SolletWalletAdapter,
  SolletExtensionWalletAdapter,
} from '@solana/wallet-adapter-wallets';

const SupportedNetwork = WalletAdapterNetwork.Devnet;
const WALLETS = [
  new PhantomWalletAdapter(),
  new SlopeWalletAdapter(),
  new SolflareWalletAdapter(),
  new TorusWalletAdapter(),
  new LedgerWalletAdapter(),
  new SolletWalletAdapter({ network: SupportedNetwork }),
  new SolletExtensionWalletAdapter({ network: SupportedNetwork }),
];

const ENDPOINT = 'https://api.devnet.solana.com';

export const config = {
  network: SupportedNetwork,
  wallets: WALLETS,
  endpoint: ENDPOINT,
};
