import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { config } from '../utils/Config';

const ExplorerLink: React.FC<{
  address: string;
  type?: 'tx' | 'address' | 'token';
}> = ({ type, address, children }) => {
  const cluster =
    {
      [WalletAdapterNetwork.Devnet]: 'devnet',
      [WalletAdapterNetwork.Testnet]: 'testnet',
    }[config.network] || '';

  return (
    <a
      href={`https://solscan.io/${
        type || 'address'
      }/${address}?cluster=${cluster}`}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

export default ExplorerLink;
