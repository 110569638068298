import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { get, set } from 'lodash';

const PREFIX = '__soledium__';

const Context = createContext<{
  save: <T = any>(key: string, value: T) => void;
  load: <T>(key: string) => T;
}>(null);

// TODO: post message when change to notify tabs
const LocalStorageProvider: React.FC = ({ children }) => {
  const [value, setValue] = useState<Record<string, any>>({});

  const save = useCallback(function (k: string, v: any) {
    setValue((x) => {
      x = set(x, k, v);
      return { ...x };
    });
  }, []);

  const load = useCallback(
    (key: string) => {
      return get(value, key);
    },
    [value],
  );

  useEffect(() => {
    try {
      const data = JSON.parse(localStorage.getItem(PREFIX));
      setValue(data || {});
    } catch (e) {
      localStorage.removeItem(PREFIX);
      console.warn('Malformed storage data. Clear');
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(PREFIX, JSON.stringify(value));
  }, [value]);

  const contextValue = {
    save,
    load,
  };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export default LocalStorageProvider;

export const useStorage = () => {
  return useContext(Context);
};

export function useStorageState<T>(path: string) {
  const { save, load } = useContext(Context);

  const value = useMemo(() => load<T>(path), [load, path]);
  const set = useCallback(
    (val: T) => {
      save(path, val);
    },
    [save, path],
  );

  return [value, set] as const;
}
