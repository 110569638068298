const Footer: React.FC = () => {
  return (
    <div className="flex items-center pt-10 p-4 justify-between text-xs">
      <div>&copy; 2021 ReddotLabs.</div>

      <div>
        <ul className="flex text-gray-700">
          <li className="px-2">About</li>
          <li className="px-2">Twitter</li>
          <li className="px-2">Github</li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
