import { ModalComponent } from '../../Modals';
import { useCallback, useEffect } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletName } from '@solana/wallet-adapter-base';
import { config } from '~src/utils/Config';

const ModalSelectWallet: ModalComponent = ({ onDismiss }) => {
  const wallet = useWallet();

  const onSelect = useCallback(
    (walletName: WalletName) => {
      console.debug('wallet changed', wallet.connecting);
      if (!wallet.connecting) {
        wallet.select(walletName);
      }
    },
    [wallet],
  );

  useEffect(() => {
    if (wallet.connected) {
      onDismiss();
    }
  }, [wallet, onDismiss]);

  return (
    <div className="modal">
      <div className="modal-header">
        <h3 className="modal-title">Select wallet</h3>
      </div>
      <div className="modal-body">
        <ul className="list-none">
          {config.wallets.map((t) => (
            <li
              key={t.name}
              className="px-5 py-4 flex items-center hover:shadow-md border border-purple-900 hover:border-purple-700 text-lg text-purple-300 rounded-md mb-4 cursor-pointer"
              onClick={() => onSelect(t.name)}
            >
              {t.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ModalSelectWallet;
