import Footer from './components/Layout/Footer';
import Header from './components/Layout/Header';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ModalProvider } from './components/Modals';
import LocalStorageProvider from './utils/LocalStorageProvider';
import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import Notifications from './components/Notifications';
import IpfsClient from './utils/IpfsClient';
import { config } from './utils/Config';
import { Suspense } from 'react';
import { ErrorBoundary } from './components/ErrorBoundary';
import loadable from '@loadable/component';

const Write = loadable(() => import('./views/Write'));
const Home = loadable(() => import('./views/Home'));
const Article = loadable(() => import('./views/Article'));
const Me = loadable(() => import('./views/Me'));

const App: React.FC = () => {
  const loader = (
    <div class="fixed w-full h-full flex items-center justify-center">
      <div class="dots-bars-6"></div>
    </div>
  );
  return (
    <ErrorBoundary>
      <Notifications>
        <LocalStorageProvider>
          <IpfsClient>
            <ConnectionProvider endpoint={config.endpoint}>
              <WalletProvider wallets={config.wallets} autoConnect>
                <BrowserRouter>
                  <ModalProvider>
                    <Suspense fallback={loader}>
                      <Header></Header>
                      <div className="flex-1">
                        <Routes>
                          <Route path="/" element={<Home />}></Route>
                          <Route path="/write" element={<Write />}></Route>
                          <Route path="/me" element={<Me />}></Route>
                          <Route
                            path="/articles/:id"
                            element={<Article />}
                          ></Route>
                        </Routes>
                      </div>
                      <Footer></Footer>
                    </Suspense>
                  </ModalProvider>
                </BrowserRouter>
              </WalletProvider>
            </ConnectionProvider>
          </IpfsClient>
        </LocalStorageProvider>
      </Notifications>
    </ErrorBoundary>
  );
};

export default App;
