/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { nanoid } from 'nanoid';
import React, { createContext, useCallback, useMemo, useState } from 'react';
import { useBodyClass } from './hooks';

interface ModalsContext {
  content?: React.ReactNode;
  onPresent: (
    content: React.ReactNode,
    backdropClick?: boolean,
    id?: string,
  ) => string;
  onDismiss: (id: string) => void;
}

export const Context = createContext<ModalsContext>({
  onPresent: () => '',
  onDismiss: () => {},
});

interface ModalInfo {
  id: string;
  content?: React.ReactNode;
  backdropClick?: boolean;
}

const modalSetter =
  (info: ModalInfo) =>
  (state: ModalInfo[]): ModalInfo[] => {
    const existed = info.id && state.some((x) => x.id === info.id);
    if (!existed) {
      return [...state, info];
    }

    return state.map((item) => (item.id !== info.id ? item : info));
  };

export const ModalProvider: React.FC = ({ children }) => {
  const [modals, setModals] = useState<ModalInfo[]>([]);

  const handlePresent = useCallback(
    (modalContent: React.ReactNode, backdropClick?: boolean, id?: string) => {
      id = id || nanoid();
      setModals(modalSetter({ id, content: modalContent, backdropClick }));
      return id;
    },
    [],
  );

  const handleDismiss = useCallback((id: string) => {
    setModals((data) => data.filter((t) => t.id !== id));
  }, []);

  const backdropClick = useCallback(() => {
    if (modals.length === 0) {
      return;
    }
    setModals((data) => data.slice(0, data.length - 1));
  }, [modals]);

  const isShow = useMemo(() => {
    return modals.length > 0;
  }, [modals?.length])

  useBodyClass(isShow, 'modal-active');

  return (
    <Context.Provider
      value={{
        content: modals,
        onPresent: handlePresent,
        onDismiss: handleDismiss,
      }}
    >
      {children}
      <div className={`modal-container fixed w-full h-full top-0 left-0 z-20 flex items-center justify-center ${isShow ? '' : 'opacity-0 pointer-events-none'}`}>
        <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-70" onClick={backdropClick}></div>
        {modals?.map(
          (modal) =>
            React.isValidElement(modal.content) &&
            React.cloneElement(modal.content, {
              onDismiss: () => handleDismiss(modal.id),
            }),
        )}
      </div>
    </Context.Provider>
  );
};

// const StyledBackdrop = styled.div`
//   background-color: #00000088;
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   z-index: 1;
// `;
