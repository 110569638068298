import {
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import NotificationPopup, {
  NotificationContent,
  NotificationType,
} from './NotificationPopup';
import { nanoid } from 'nanoid';

type NofityFn = (
  type: NotificationType,
  title: string,
  message: string,
  txid?: string,
  id?: string,
) => string;

const Context = createContext<NofityFn>(null);

const AUTO_DISMISS_TIMEOUT = 5000;

const Notifications: React.FC = ({ children }) => {
  const [items, setItems] = useState<NotificationContent[]>([]);

  const send = useCallback((content: NotificationContent) => {
    const id = content.id || nanoid();

    setItems((items) => {
      if (content.id != null && items.some((t) => t.id === content.id)) {
        return items.map((x) => {
          return x.id === content.id ? { ...content } : x;
        });
      }

      return [...items, { ...content, id }];
    });

    if (content.type !== 'wait') {
      setTimeout(() => {
        setItems((t) => t.filter((x) => x.id !== id));
      }, AUTO_DISMISS_TIMEOUT);
    }

    return id
  }, []);

  const dismiss = useCallback((id: string) => {
    setItems((t) => t.filter((x) => x.id !== id));
  }, []);

  const notify = useCallback(
    (
      type: NotificationType,
      title: string,
      message: string,
      txid?: string,
      id?: string,
    ) => {
      return send({ id, type, title, message, txid });
    },
    [send],
  );

  return (
    <>
      <div className="fixed top-24 right-4 z-50 bg-transparent w-96">
        {items.map((t) => (
          <NotificationPopup key={t.id} {...t} onDismiss={dismiss} />
        ))}
      </div>
      <Context.Provider value={notify}>{children}</Context.Provider>
    </>
  );
};

export default Notifications;

export const useNotify = () => useContext(Context);
