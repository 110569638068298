import { useWallet } from '@solana/wallet-adapter-react';
import { Link } from 'react-router-dom';

const Navbar: React.FC = () => {
  const { connected } = useWallet();

  return (
    <nav className="mx-4">
      <ul>
        {connected ? (
          <>
            <li className="px-3">
              <Link
                to="/me"
                className="text-gray-500 hover:text-gray-400 font-sans"
              >
                My posts
              </Link>
            </li>
          </>
        ) : (
          <>
            <li className="px-3">
              <a
                className="text-gray-500 hover:text-gray-400 font-sans"
                href="/"
              >
                Our story
              </a>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
