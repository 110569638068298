import { PublicKey } from "@solana/web3.js";
import jazzicon from "@metamask/jazzicon";
import { useMemo } from "react";
import BN from "bn.js";
import bs58 from "bs58";

const Jazzicon: React.FC<{
  size: number;
  address: string | PublicKey;
  className?: string;
}> = ({ size, address, className }) => {
  const el = useMemo(
    () =>
      jazzicon(
        size,
        new BN(bs58.decode(address.toString().substring(0, 8))).toNumber()
      ),
    [address, size]
  );

  return (
    <div
      className={className}
      ref={(nodeElement) => {
        nodeElement && nodeElement.appendChild(el);
      }}
    />
  );
};

export default Jazzicon;
