import { createContext, useContext } from "react"
import {create, IPFSHTTPClient} from 'ipfs-http-client'

const client = create({
  url: 'https://ipfs.infura.io:5001/api/v0'
})
const Context = createContext<IPFSHTTPClient>(null)

const IpfsClient: React.FC = ({children}) => {
  return <Context.Provider value={client}>{children}</Context.Provider>
}

export default IpfsClient

export const useIpfs = () => useContext(Context)