import { useWallet } from '@solana/wallet-adapter-react';
import { Link } from 'react-router-dom';
import Jazzicon from '~src/components/Jazzicon';
import { useModal } from '~src/components/Modals';
import ModalSelectWallet from './ModalSelectWallet';

const User: React.FC = () => {
  const [selectWallet] = useModal(<ModalSelectWallet></ModalSelectWallet>);
  const wallet = useWallet();

  if (!wallet.connected) {
    return (
      <>
        <button
          onClick={selectWallet}
          className="px-5 py-2 border-0 bg-purple-800 hover:shadow-lg rounded-full font-sans"
        >
          Connect
        </button>
      </>
    );
  }

  const shortenAddr = wallet.publicKey.toBase58().substring(0, 8);

  return (
    <>
      <Link
        to="/write"
        className="px-5 py-2 border-0 bg-purple-800 hover:shadow-lg rounded-full font-sans"
      >
        <i class="uil uil-edit-alt mr-2"></i>
        Write
      </Link>

      <button className="px-5 py-2 flex items-center text-sm">
        <Jazzicon
          size={28}
          className="block mr-1 leading-none"
          address={wallet.publicKey}
        ></Jazzicon>
        {shortenAddr}
      </button>
    </>
  );
};

export default User;
