export interface ModalProps {
  onDismiss?: () => void;
}


export const Modal: React.FC = ({children}) => {
  return <div className="modal">
    {children}
  </div>
} 


export type ModalComponent<T = {}> = React.FC<ModalProps & T>;
